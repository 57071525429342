<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px;">

    <v-row class="mx-6">

      <!-- Button --
      <v-col cols="12">
        <v-row justify="end">
          <v-btn
              class="btn-primary"
              color="primary"
              @click="setShowUserAddDialog(true)">
            {{ $t('accounts.tabs.users.buttons.add') }}
          </v-btn>
        </v-row>
      </v-col-->

      <!-- Datatable -->
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalItems"
            :options.sync="pagination"
            :loading="loading"
            :items-per-page="20"
            sort-by="createdAt"
            :sort-desc="false"
            :footer-props="{'items-per-page-options': itemsPerPage}"
            class="elevation-1">

          <!-- Name -->
          <template v-slot:item.name="{item}">
            <router-link :to="{name: 'Component', params: { id: item.id }}">{{ item.name }}</router-link>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import vuetifyDataTable from "@/mixins/vuetifyDataTable";

export default {
  name: "MaterialComponentsTab",

  mixins: [vuetifyDataTable],

  props: {
    material: {type: Object, default: () => null}
  },

  data() {
    return {
      headers: [
        {
          text: this.$t('components.tab.datatable.headers.name'),
          value: "name",
          sortable: false,
          width: "40%"
        },
      ],
      URL_API: {
        GET_ALL: "/components"
      },

      filters: {
        materials: [this.material.id]
      },

      currentComponent: null,

    }
  }
}
</script>

<style scoped>

</style>
